import React from "react";

const Footer = () => {
  return (
    <div className="th-footer">
      <div className="container">
        <div className="row">
          <div className="col-sm text-left">
            <h1 className="th-footer-heading">Contact Us</h1>
            <p className="th-footer-text">nss@iitr.ac.in</p>
            <p className="th-footer-text">+91-6206115050</p>
            <p className="th-footer-text">NSS Office, MAC 2nd Floor, IITR</p>
          </div>
          <div className="col-sm th-footer-center">
            <h1 className="th-footer-heading">About Us</h1>
            <p className="th-footer-text">Conclave</p>
            <p className="th-footer-text">Conference</p>
          </div>
          <div className="col-sm th-footer-right">
            <h1 className="th-footer-heading">Follow Us</h1>
            <a href="https://www.facebook.com/nssiitr"
                  target="_blank"
                  rel="noreferrer"
                  className="th-footer-text">Facebook</a>
                  <p></p>
            <a href="https://www.instagram.com/nssiitr/"
                  target="_blank"
                  rel="noreferrer"
                  className="th-footer-text">Instagram</a>
                  <p></p>
            <a href="https://www.linkedin.com/company/nssiitr/mycompany/"
                  target="_blank"
                  rel="noreferrer"
                  className="th-footer-text"
                  >LinkedIn</a>
                  <p></p>
            <a href="https://twitter.com/NSSIITROORKEE"
                  target="_blank"
                  rel="noreferrer"
                  className="th-footer-text">Twitter</a>
                  <p></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
