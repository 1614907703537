import React, { Component } from "react";
import Logo from "../../Assets/images/logo.svg";
import { Menu } from "react-feather";
import { Link } from "react-router-dom";
import SideDrawer from "../SideDrawer";
import BackDrop from "../SideDrawer/Backdrop";
import { NavLink } from "react-router-dom";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideDrawerOpen: false,
    };
  }

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  openSideDrawer = () => {
    this.setState({ sideDrawerOpen: !this.state.sideDrawerOpen });
  };

  closeSideDrawer = () => {
    this.setState({ sideDrawerOpen: !this.state.sideDrawerOpen });
  };

  render() {
    let backdrop;
    if (this.state.sideDrawerOpen) {
      backdrop = <BackDrop click={this.openSideDrawer} />;
    }

    return (
      <div className="pr-3 pl-3">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <Link to="/">
              <img src={Logo} alt="logo" className="th-header-logo" />
            </Link>

            <div className="ms-3 me-5">
              <p className="mb-0 th-nss">Sustainability</p>
              <p className="mb-0 th-clg-name">Conclave</p>
            </div>
            {/* <Link to="">
              <p className="mb-0 ml-5 th-nss">About Us</p>
            </Link> */}
            <div className="th-header-menu">
              <NavLink
                to="/"
                activeClassName="active"
                className="th-footer-link"
              >
                <p className="ms-5 th-nss">Home</p>
              </NavLink>
              <NavLink
                to="/events"
                activeClassName="active"
                className="th-footer-link"
              >
                <p className="ms-5  th-nss">Events</p>
              </NavLink>
              <NavLink
                to="/conference"
                activeClassName="active"
                className="th-footer-link"
              >
                <p className="ms-5 th-nss">Conference</p>
              </NavLink>
              <NavLink
                to="/speakers"
                activeClassName="active"
                className="th-footer-link"
              >
                <p className="ms-5  th-nss">Speakers</p>
              </NavLink>
              {/* <NavLink
                to="/"
                activeClassName="active"
                className="th-footer-link"
              >
                <p className="ms-5 th-nss">Contact Us</p>
              </NavLink> */}
            </div>
          </div>
          <Menu
            size={this.props.width > 992 ? 32 : 24}
            onClick={this.drawerToggleClickHandler}
            className="th-theme-color"
            style={{ cursor: "pointer" }}
          />
        </div>
        <SideDrawer
          show={this.state.sideDrawerOpen}
          clicked={this.drawerToggleClickHandler}
        />
        {backdrop}
      </div>
    );
  }
}

export default Header;
