import React from "react";
import HeaderMob from "./MobileView";
import HeaderWeb from "./WebView";
import { useWindowWidthAndHeight } from "../../../CustomHook";

const HeaderHome = () => {
  const [width] = useWindowWidthAndHeight();

  return (
    <React.Fragment>
      {width > 780 ? <HeaderWeb width={width} /> : <HeaderMob width={width} />}
    </React.Fragment>
  );
};

export default HeaderHome;
