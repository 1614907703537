import React, { Component, Suspense } from "react";
import { Routes, Route } from "react-router-dom";

import Header from "../App/Layouts/Header/index";
import Footer from "../App/Layouts/Footer/index";
const Home = React.lazy(() => import("../App/Screens/Home/index"));
const Event = React.lazy(() => import("../App/Screens/Events/index"));
const Speaker = React.lazy(() => import("../App/Screens/Speaker/index"));
const Conference = React.lazy(() => import("../App/Screens/Conference/index"));
const Form = React.lazy(() => import("../App/Screens/Form/index"));

class App extends Component {
  render() {
    return (
      <div>
        <Header />
        <Suspense fallback={<div />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/events" element={<Event />} />
            <Route path="/speakers" element={<Speaker />} />
            <Route path="/conference" element={<Conference />} />
            <Route path="/form" element={<Form />} />
          </Routes>
        </Suspense>
        <Footer />
      </div>
    );
  }
}

export default App;
