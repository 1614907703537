import React from "react";
import { useWindowWidthAndHeight } from "../../../CustomHook";
import SidebarMob from "./SidebarMob";

const SideDrawer = (props) => {
  let [width] = useWindowWidthAndHeight();
  return (
    <div>
      {width < 780 && (
        <SidebarMob show={props.show} clicked={props.clicked} width={width} />
      )}
    </div>
  );
};

export default SideDrawer;
