import React, { Component } from "react";
import { Link } from "react-router-dom";
import { X } from "react-feather";

class SideDrawerMob extends Component {
  render() {
    let drawerClasses = "th-sidedrawer-mob";
    if (this.props.show) {
      drawerClasses = "th-sidedrawer-mob open";
    }

    return (
      <div>
        <div className={drawerClasses}>
          <div className="pt-2 pb-4 pl-3 pr-3">
            <div className="d-flex justify-content-end mb-3">
              <X
                size={28}
                onClick={this.props.clicked}
                className="th-theme-color mt-1 ml-2 float-right"
                style={{ cursor: "pointer" }}
              />
            </div>
            <div>
              <Link to="/" onClick={this.props.clicked} className="th-link">
                <p className="th-sidebar-list-item mb-3 p-2">Events</p>
              </Link>
              <Link
                to="/conference"
                onClick={this.props.clicked}
                className="th-link"
              >
                <p className="th-sidebar-list-item mb-3 p-2">Conferene</p>
              </Link>
              <Link
                to="/speakers"
                onClick={this.props.clicked}
                className="th-link"
              >
                <p className="th-sidebar-list-item mb-3 p-2">Speakers</p>
              </Link>
              {/* <Link to="/" onClick={this.props.clicked} className="th-link">
                <p className="th-sidebar-list-item mb-3 p-2">Contact Us</p>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SideDrawerMob;
